
.page-not-found-section{
    position: relative;
    background: #fff;
    padding: 155px 45px 0 45px;
    min-width: 100vw;
    min-height: 100vh;
    text-align: center;
}
.page-not-found-section h1{
    font-size: 110px;
}
.page-not-found-section h2{
    font-size: 70px;
}
.page-not-found-section h1,
.page-not-found-section h2{
    margin-bottom: 70px;
}
.page-not-found-section a{
    display: inline-block;
    padding: 10px 30px;
    letter-spacing: 1px;
    font-weight: 600;
    background: linear-gradient(90deg,#67988E,#184438);
    border-radius: 50px;
    text-decoration: none;
    color: #fff;
}