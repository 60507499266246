
.home-page .commands-section .download-table .consult-group{
    position: fixed;
    width: 100%;  height: 100%;
    top: 0;  left: 0;
    z-index: 100;
}
.home-page .commands-section .download-table .consult-group .wrapper{
    position: fixed;
    width: 100%;  height: 100%;
    top: 0;  left: 0;
    background: #67988e;
    opacity: .75;
}
.home-page .commands-section .download-table .consult-group .validate-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    width: 600px;
    border-radius: 30px;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-title{
    position: relative;
    width: 100%;
    background: #184438;
    text-align: center;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-title span{
    color: #fff;
    display: inline-block;
    padding: 16px 0;
    font-size: 25px;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-form{
    padding: 20px 20px;
    min-height: 300px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-form ul li span {
    margin: 0 10px 4px 0;
    display: inline-block;
    font-size: 16px;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-form ul li.li-title {
    font-size: 20px;
    margin-bottom: 5px;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-form hr{
    margin: 7px 0;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-form input,
.home-page .commands-section .download-table .consult-group .validate-box .validate-form textarea,
.home-page .commands-section .download-table .consult-group .validate-box .validate-form select {
    border-radius: 20px;
    border: 1px solid #a7a7a7;
    padding: 8px 15px;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-form input:focus,
.home-page .commands-section .download-table .consult-group .validate-box .validate-form textarea:focus,
.home-page .commands-section .download-table .consult-group .validate-box .validate-form select:focus {
    box-shadow: none;
    border: 1px solid #6FCFF5;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-form input::placeholder {
    color: #A2A8B4;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-form label {
    padding: 8px 0
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-submit{
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 100px;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-submit span{
    background: #184438;
    color: #fff;
    font-size: 20px;
    padding: 8px 25px;
    border-radius: 30px;
    cursor: pointer;
}
.home-page .commands-section .download-table .consult-group .validate-box .validate-submit span svg{
    vertical-align: middle;
    margin-left: 10px;
}


/******** Begin scrollbar css ********/
.home-page .commands-section .download-table .consult-group .validate-box .validate-form::-webkit-scrollbar {
    width: 10px;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-form::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-form::-webkit-scrollbar-thumb {
    background: #67988E;
    border-radius: 20px;
}

.home-page .commands-section .download-table .consult-group .validate-box .validate-form::-webkit-scrollbar-thumb:hover {
    background: #184438;
}

/******** End scrollbar css ********/
