
.statistiques-global{
    position: relative;
    width: 100%;
    background: #fff;
    border-radius: 20px;
    padding: 20px 30px;
    margin-top: 30px;
}
.statistiques-global h1{
    margin-bottom: 0;
    font-size: 23px;
}
.statistiques-global h1 svg{
    vertical-align: text-top;
}
.statistiques-global .box{
    position: relative;
    border: 2px solid #8EA5B2;
    padding: 10px 20px;
    margin: 15px 15px 40px;
    border-radius: 30px;
}
.statistiques-global .box h2{
    font-size: 18px;
}
.statistiques-global h2 svg{
    vertical-align: bottom;
}
.statistiques-global h2 span{
    margin-left: 11px;
}
.statistiques-global .box .statistique-content{
    display: inline-block;
}
.statistiques-global .box .statistique-content.full-width{
    margin-top: 80px;
    width: 100%;
}
.statistiques-global .box .statistique-content.half-width{
    margin-top: 25px;
    width: 50%;
}
.statistiques-global .box .filter{
    position: absolute;
    right: 20px;
    top: 20px;
}
.statistiques-global .box .filter ul{
    position: relative;
    background: #D9E1E7;
    padding: 7px 10px;
    border-radius: 50px;
    margin-bottom: 10px;
    display: inline-block;
}
.statistiques-global .box .filter ul li{
    position: relative;
    display: inline-block;
    padding: 3px 7px;
    border-radius: 50px;
    font-size: 15px;
    cursor: pointer;
}
.statistiques-global .box .filter ul li.filter-active{
    color: #fff;
    background: #262626;
}
.statistiques-global .box .filter ul li .interval-pop-up{
    display: none;
    position: absolute;
    left: 50%;
    top: 25px;
    transform: translatex(-70%);
    background: #262626;
    z-index: 10;
    width: 500px;
    padding: 10px 15px;
    border-radius: 25px;
}
.statistiques-global .box .filter ul li:hover .interval-pop-up{
    display: block;
}
.statistiques-global .box .filter ul li .interval-pop-up::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    background: transparent;
    width: 100%;
    height: 220px;
}
.statistiques-global .box .filter ul li .interval-pop-up label{
    white-space: nowrap;
    font-size: 13px;
    color: #fff;
}
.statistiques-global .box .filter ul li .interval-pop-up input{
    margin-top: 4px;
    font-size: 13px;
    border: none;
}
.statistiques-global .box .filter ul li .interval-pop-up input:focus {
    box-shadow: none;
    border: none
}
.statistiques-global .box .filter ul li .interval-pop-up .row{
    margin-bottom: 5px;
}
.statistiques-global .box .filter ul li .interval-pop-up span{
    display: inline-block;
    background: #fff;
    color: #262626;
    padding: 4px 15px;
    border-radius: 15px;
    margin-top: 13px;
}