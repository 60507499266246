@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
* {
    margin: 0;
    padding: 0;
}

.padding-right {
    margin: 0 !important;
    padding: 0 10px 0 0 !important;
}

.padding-left {
    margin: 0 !important;
    padding: 0 0 0 0px !important;
}

.margin-bottom {
    margin: 0 0 22px 0 !important;
}

html,
body {
   background: #d9d9d9; 
   /* font-family: 'Ubuntu', sans-serif; */
   font-family: 'Roboto', sans-serif;
}
.overflow-x{
    overflow-x: hidden;
}
.position-relative{
    position: relative;
}
.row {
    margin-bottom: 20px;
}
.btn{
    border: none !important;
}
svg {
    position: relative;
    vertical-align: baseline;
}
ul{
    padding-left: 0;
    margin-bottom: 0;
}
li{
    list-style: none;
}
.text-center{
    text-align: center;
}
