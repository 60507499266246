
/***************************************** Begin auth-section *****************************************/
.auth-section {
    /* background: url(https://jooinn.com/images/city-street-1.jpg);
    background-size: cover; */
    width: 100%;
    height: 100vh;
  }
  
  .auth-section .login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background: #fff;
    box-sizing: border-box;
    border-radius: 48px;
    overflow: hidden;
  }
  
  .auth-section .login h1 {
    color: #fff;
    background: linear-gradient(90deg,#67988E,#184438);
    text-align: center;
    font-size: 28px;
    padding: 15px;
  }
  .auth-section .login form{ 
    padding: 30px 30px;
  }
  .auth-section .login .box {
    position: relative;
    width: 100%;
    margin: 30px 0;
  }
  
  .auth-section .login .box input,
  .auth-section .login .box textarea {
    position: relative;
    border: none;
    outline: none;
    width: 100%;
    font-size: 22px;
    padding: 8px 0;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    background: transparent;
    z-index: 2;
    transition: .9s;
  }
  
  .auth-section .login .box input:focus,
  .auth-section .login .box textarea:focus {
    border-color: #184438;
    box-shadow: none !important;
  }
  
  .auth-section .login .box span {
    display: inline-block;
    position: absolute;
    left: 0;
    color: rgba(0, 0, 0, .5);
    z-index: 1;
    transition: .9s;
  }

  .auth-section .login .box span.error-message{
    color: #f00;
  }

  .auth-section .login .box input+span {
    top: 31%;
  }
  
  .auth-section .login .box input:focus+span,
  .auth-section .login .box input:valid+span {
    color: #184438;
    top: -20%;
  }
  
  .auth-section .login .box textarea+span {
    top: 13px;
  }
  
  .auth-section .login .box textarea:focus+span,
  .auth-section .login .box textarea:valid+span {
    color: #184438;
    top: -11px;
  }
  
  .auth-section .login button {
    display: inline-block;
    position: relative;
    color: #fff;
    background: linear-gradient(90deg,#67988E,#184438);
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: 5px 30px;
    border-radius: 30px;
  }
  .auth-section .login button svg{
    vertical-align: middle;
    margin-left: 10px;
  }
  
  
  /***************************************** End auth-section *****************************************/