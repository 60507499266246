
.etat span {
    color: #fff;
    border-radius: 21px;
    padding: 7px 10px;
}

.etat span.non-traite-color, .etat span.non-traite-color::before {
    background: #6FCFF5;
}

.etat span.accorder-color, .etat span.accorder-color::before {
    background: #AED581;
}

.etat span.refuser-color, .etat span.refuser-color::before {
    background: #f00;
}

.etat span.approuver-color, .etat span.approuver-color::before {
    background: #107E39;
}

.etat span.annuler-color, .etat span.annuler-color::before {
    background: #927c7a;
}

.etat span.valider-color, .etat span.valider-color::before {
    background: #2BF5BD;
}

.etat span.financable-color, .etat span.financable-color::before {
    background: #372580;
}
.etat span.non-financable-color, .etat span.non-financable-color::before {
    background: #B149E6;
}
.etat span.rejeter-color, .etat span.rejeter-color::before {
    background: #ce1e8b;
}
.etat span.controler-color, .etat span.controler-color::before {
    background: #706211;
}
.etat span.payment-accepter-color, .etat span.payment-accepter-color::before {
    background: #4eac10;
}
.etat span.complement-color, .etat span.complement-color::before {
    background: #7a6770;
}
.etat span.anomalie-color, .etat span.anomalie-color::before {
    background: #477bc9;
}
.etat span.fbl-erronee-color, .etat span.fbl-erronee-color::before {
    background: #ff5722;
}
.etat span.finan-eme-dossier-color, .etat span.finan-eme-dossier-color::before {
    background: #8de7ab;
}
.etat span.dossier-errone-color, .etat span.dossier-errone-color::before {
    background: hsl(14, 86%, 22%);
}
.etat span.a-revoir-color, .etat span.a-revoir-color::before {
    background: hsl(14, 80%, 62%);
}
