

.home-page .commands-section .table th.th-filter .fa{
    margin-left: 4px;
    cursor: pointer;
}
.home-page .commands-section .table th.th-filter ul{
    position: absolute;
    top: 40px;
    background: #679d91;
    color: #fff;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 0;
}
.home-page .commands-section .table th.th-filter ul.translate-30{
    transform: translateX(-30%);
}
.home-page .commands-section .table th.th-filter ul li{
    list-style: none;
    cursor: pointer;
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
    font-weight: 400;
}
.home-page .commands-section .table th.th-filter .thead-calendar-popup{
    position: fixed;
    top: 0;  left: 0;
    width: 100%;  height: 100%;
    z-index: 100;
}
.home-page .commands-section .table th.th-filter .thead-calendar-popup .wrapper{
    position: absolute;
    width: 100%; height: 100%;
    background: #67988e;
    opacity: .75;
}
.home-page .commands-section .table th.th-filter .thead-calendar-popup .box{
    position: absolute;
    top: 50%;  left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    width: 400px;
    min-height: 200px;
    border-radius: 20px;
    overflow: hidden;
}
.home-page .commands-section .table th.th-filter .thead-calendar-popup .box h2{  
    background: #67988e;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}
.home-page .commands-section .table th.th-filter .thead-calendar-popup .box input{  
    margin: 40px 20px;
    width: calc(100% - 40px);
    outline: none;
    box-shadow: none;
}
.home-page .commands-section .table th.th-filter .thead-calendar-popup .box button{  
    background: #67988e;
    color: #fff;
    border: none;
    outline: none;
    padding: 0px 25px !important;
    margin-bottom: 10px;
    border-radius: 15px;
}