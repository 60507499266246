

.home-page {
    padding: 130px 25px;
}
/*************************** Begin Filter Section ***************************/
.home-page .filter-section {
    position: relative;
    background: #fff;
    border-radius: 37px;
    padding: 15px 25px;
    height: 100%;
    overflow: hidden;
    /* box-shadow: 0 0 20px rgb(0 0 0 / 20%); */
}

.home-page .filter-section.active {
    height: 60px !important;
}
.home-page .filter-section h2{
    font-size: 25px;
}
.home-page .filter-section span.popup {
    position: absolute;
    right: 25px;
    top: 23px;
    cursor: pointer;
}
.home-page .filter-section .search-by-id-section,
.home-page .filter-section .search-advanced-section {
    margin: 20px 0;
}

.home-page .filter-section .search-by-id-section .form,
.home-page .filter-section .search-advanced-section {
    /* border: 1px solid #DCDFE6; */
    border: 1px solid #c6cbd5;
    padding: 10px 15px;
    border-radius: 20px;
}

.home-page .filter-section label {
    color: #777F8D;
    margin-bottom: 4px;
}

.home-page .filter-section input,
.home-page .filter-section select {
    border-radius: 20px;
    border: 1px solid #DCDFE6;
    padding: 5px 15px;
}

.home-page .filter-section select {
    margin-left: 5px;
}

.home-page .filter-section input:focus,
.home-page .filter-section select:focus {
    box-shadow: none;
    border: 1px solid #6FCFF5;
}

.home-page .filter-section input::placeholder {
    color: #A2A8B4;
}

.home-page .filter-section span.sub-title {
    color: #262626;
    font-weight: 500;
}

.home-page .filter-section span.btn {
    background: linear-gradient(90deg, #67988E, #184438);
    /* box-shadow: 0 4px 15px 0 #ff7b61; */
    color: #fff;
    border-radius: 20px;
    padding: 4px 17px;
}

.home-page .absolute-bottom-right {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateX(-20px);
}

.home-page .position-relative {
    position: relative;
}

/*************************** End Filter Section ***************************/
/*************************** Begin Taches Section ***************************/
.home-page .taches-section {
    background: #fff;
    border-radius: 40px;
    padding: 20px 5px;
    /* box-shadow: 0 0 20px rgb(0 0 0 / 20%); */
}

.home-page .taches-section h2 {
    padding-left: 25px;
    margin-bottom: 17px;
    font-size: 25px
}

.home-page .taches-section ul {
    margin: 0;
    padding: 0;
}

.home-page .taches-section ul li {
    list-style: none;
    margin: 0 15px;
    padding: 8px 0 8px 7px;
    position: relative;
    cursor: pointer;
    border-radius: 20px;
}

.home-page .taches-section ul li:first-of-type {
    margin: 0;
}

.home-page .taches-section ul li .no-color {
    display: block;
}

.home-page .taches-section ul li .with-color {
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    ;
}

.home-page .taches-section ul li div span {
    margin-left: 6px;
    font-size: 15px;
}
.home-page .taches-section ul li.tache-active {
    /* background: linear-gradient(90deg, #ff61ab, #ff6161, #ff6392); */
    background: linear-gradient(90deg, #67988E, #184438);
}

.home-page .taches-section ul li.tache-active .no-color {
    opacity: 0;
}

.home-page .taches-section ul li.tache-active .with-color {
    display: block;
}
.home-page .taches-section ul b{
    display: inline-block;
    margin: 0 5px;
    font-size: 18px;
}
.home-page .taches-section ul b:first-of-type{
    margin: 8px 5px 0;
}
.home-page .taches-section ul b:last-of-type{
    margin: 15px 5px 0;
}

.home-page .taches-section svg {
    vertical-align: sub;
}
/*************************** End Taches Section ***************************/

/*************************** Begin commands Section ***************************/
.home-page .commands-section {
    position: relative;
    background: #fff;
    padding: 20px 20px;
    /* border-top-left-radius: 36px;
    border-top-right-radius: 36px; */
    border-radius: 36px;
    min-height: 637px;
    width: 100%;
    /* text-align: right; */
    /* box-shadow: 0 0 20px rgb(0 0 0 / 20%); */
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: middle;
    background: transparent;
}

.home-page .commands-section h2 {
    margin-bottom: 10px;
    padding-left: 15px;
    font-size: 25px;
    text-align: left;
}
.home-page .commands-section .download-table{
    position: relative;
    margin: 23px 0 10px;
}
.home-page .commands-section .download-table > span{
    font-size: 19px;
    background: linear-gradient(90deg, #67988E, #184438);
    padding: 6px 20px;
    border-radius: 50px;
    cursor: pointer;
    color: #fff;
    margin-right: 7px;
}
.home-page .commands-section .download-table span.right-side{
    float: right;
    padding: 3px 20px;
}
.home-page .commands-section .vehicule-checkbox span{
    font-weight: 600;
    font-size: 18px;
    margin-right: 15px;
}
.home-page .commands-section .vehicule-checkbox .box{
    display: inline-block;
    position: relative;
    border-radius: 80px;
    width: 170px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
}
.home-page .commands-section .vehicule-checkbox .box input{
    position: relative;
    appearance: none;
    width: 100%;   height: 100%;
    background: #ccc;
    border-radius: 80px;
    transition: .9s;
    cursor: pointer;
}
.home-page .commands-section .vehicule-checkbox .box input:checked{
    /* background: #00bcd4;
    box-shadow: inset 0 5px 10px rgba(0,0,0,.5); */
    background: linear-gradient(90deg, #67988E, #184438);
}
.home-page .commands-section .vehicule-checkbox .box label{
    position: absolute;
    top: 0;  left: 0;
    width: 50%;  height: 100%;
    background: #959595;
    border-radius: 60px;
    transition: .9s;
    cursor: pointer;
}
.home-page .commands-section .vehicule-checkbox .box input:checked + label{
    left: 100%;
    transform: translateX(-100%);
    background: #619b8b;
}
.home-page .commands-section .vehicule-checkbox .box label::before{
    content: '';
    position: absolute;
    top: 50%;   right: 20%; 
    transform: translateY(-50%);
    width: 15px;  height: 15px;
    background: #666565;
    border-radius: 50%; 
    transition: .9s;
}
.home-page .commands-section .vehicule-checkbox .box input:checked + label::before{
    background: #184438;
    box-shadow: 0 0 20px #184438;
}











.home-page .commands-section .no-data-wrapper{
    text-align: center;
    color: #184438;
    font-weight: 600;
    font-size: 30px;
    margin-top: 100px;
}
.home-page .commands-section .table {
    width: 100%;
    display: block;
    white-space: nowrap;
    margin-bottom: 0;
    overflow-x: auto;
    padding-bottom: 15px;
    min-height: 200px;
}

.home-page .commands-section .table thead {
    position: relative;
    height: 40px;
    line-height: 40px;
}

.home-page .commands-section .table tbody {
    border-top: none;
}

.home-page .commands-section .table tr {
    position: relative;
    border-bottom: 1px solid #dddddd;
}

.home-page .commands-section .table tbody tr td,
.home-page .commands-section .table thead tr th {
    font-size: 16px;
    text-align: center;
    position: relative;
}

.home-page .commands-section .table tbody tr td{
    font-size: 15px;
}

.home-page .commands-section .table thead tr th span{
    position: absolute;
    top: 38px;
    left: 1px;
    display: none;
    padding: 0px 10px;
    background: #000000;
    color: #fff;
    font-size: 12px;
    border-radius: 9px;
    z-index: 10;
    line-height: 30px;
    opacity: .85;
}
.home-page .commands-section .table thead tr th:hover span{
    display: inline-block;
}

.home-page .commands-section .table tbody tr td:last-child,
.home-page .commands-section .table thead tr th:last-child {
    text-align: right;
    width: 100% !important;
    cursor: pointer;
}
.home-page .commands-section .table div#rect-div {
  width: 17px;
  height: 17px;
  border: 1px solid #262626;
  display: inline-block;
}
.home-page .commands-section .table div.mini-rect-div {
  width: 10px;
  height: 10px;
  border: 1px solid #262626;
  display: inline-block;
  cursor: pointer;
}
.home-page .commands-section .table div.mini-rect-div.active {
    border: none;
    background: #67988e;
}
.home-page .commands-section .table tbody tr td svg{
    vertical-align: middle;
}
/*************************** End commands Section ***************************/

/******** Begin scrollbar css ********/
.home-page .commands-section .table::-webkit-scrollbar {
    /* width: 4px; */
    height: 13px;
}

.home-page .commands-section .table::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
}

.home-page .commands-section .table::-webkit-scrollbar-thumb {
    background: #67988E;
    border-radius: 20px;
}

.home-page .commands-section .table::-webkit-scrollbar-thumb:hover {
    background: #184438;
}

/******** End scrollbar css ********/


/*************************** Begin media ***************************/
@media screen and (max-width: 767px) {
    .absolute-bottom-right {
        position: relative;
        transform: translateX(0);
    }

    .form-group {
        margin: 10px 0;
    }
}


/************************************** Pagination Style **********************************************/
.home-page .pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.home-page .pagination .pagination-content {
    padding: 0;
    margin: 0;
    white-space: nowrap;
    border-radius: 8px;
    border: 1px solid #b8bdc7;
}

.home-page .pagination .pagination-content span {
    list-style: none;
    background: #fff;
    padding: 10px 15px;
    display: inline-block;
    cursor: pointer;
}

@media (max-width: 556px) {
    .home-page .pagination .pagination-content span {
        padding: 10px 7px;
    }
}

.home-page .pagination .pagination-content span:hover {
    background: #e7e7e7;
}

.home-page .pagination .pagination-content span:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border-right: 1px solid #dbd4d4;
}

.home-page .pagination .pagination-content span:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border-left: 1px solid #dbd4d4;
}

.home-page .pagination .pagination-content span.page-number-active {
    background: #e7e7e7;
}
