.stock-page {
    padding: 130px 25px 0px;
}

/*************************** Begin Filter Section ***************************/
.stock-page .filter-section,.stock-page .statistic-section {
    position: relative;
    background: #fff;
    border-radius: 37px;
    padding: 15px 25px;
    margin-bottom: 25px;
    height: 100%;
    overflow: hidden;
    /* box-shadow: 0 0 20px rgb(0 0 0 / 20%); */
}

.stock-page .filter-section.active, .stock-page .statistic-section.active {
    height: 50px !important;
    padding: 12px 25px;
}

.stock-page .filter-section h2, .stock-page .statistic-section h2 {
    font-size: 23px;
}

.stock-page .filter-section span.popup, .stock-page .statistic-section span.popup {
    position: absolute;
    right: 25px;
    top: 17px;
    cursor: pointer;
}

.stock-page .filter-section .search-advanced-section, .stock-page .statistic-section .search-advanced-section {
    /* border: 1px solid #DCDFE6; */
    border: 1px solid #c6cbd5;
    padding: 10px 15px;
    border-radius: 20px;
    margin: 20px 0;
}

.stock-page .filter-section label, .stock-page .statistic-section label {
    color: #777F8D;
    margin-bottom: 4px;
    color: #3b3f46;
}
.stock-page .statistic-section span {
    display: inline-block;
    margin-left: 20px;
    color: #3b3f46;
}
.stock-page .filter-section input,
.stock-page .filter-section select {
    border-radius: 20px;
    border: 1px solid #DCDFE6;
    padding: 5px 15px;
}

.stock-page .filter-section select {
    margin-left: 5px;
}

.stock-page .filter-section input:focus,
.stock-page .filter-section select:focus {
    box-shadow: none;
    border: 1px solid #6FCFF5;
}

.stock-page .filter-section input::placeholder {
    color: #A2A8B4;
}

.stock-page .filter-section span.sub-title {
    color: #262626;
    font-weight: 500;
}

.stock-page .filter-section span.btn {
    background: linear-gradient(90deg, #67988E, #184438);
    /* box-shadow: 0 4px 15px 0 #ff7b61; */
    color: #fff;
    border-radius: 20px;
    padding: 4px 17px;
}

.stock-page .absolute-bottom-right {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateX(-20px);
}

.stock-page .position-relative {
    position: relative;
}

/*************************** End Filter Section ***************************/





/*************************** Begin Table Section ***************************/
.stock-page .info-section {
    position: relative;
    background: #fff;
    padding: 20px 20px 30px;
    /* border-top-left-radius: 36px;
    border-top-right-radius: 36px; */
    border-radius: 36px;
    /* min-height: 637px; */
    width: 100%;
    /* box-shadow: 0 0 20px rgb(0 0 0 / 20%); */
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: middle;
    background: transparent;
}

.stock-page .info-section h2 {
    margin-bottom: 10px;
    padding-left: 15px;
    font-size: 25px;
}

.stock-page .info-section .download-table {
    position: relative;
    text-align: right;
    margin-bottom: 20px;
}

.stock-page .info-section .download-table span {
    font-size: 19px;
    background: linear-gradient(90deg, #67988E, #184438);
    padding: 8px 20px;
    border-radius: 50px;
    cursor: pointer;
    color: #fff;
}

.stock-page .info-section .no-data-wrapper {
    text-align: center;
    color: #184438;
    font-weight: 600;
    font-size: 30px;
}

.stock-page .info-section .wrapper {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
}

.stock-page .info-section .wrapper .div1 {
    height: 20px;
}

.stock-page .info-section .table {
    width: 100%;
    display: block;
    white-space: nowrap;
    margin-bottom: 0;
    overflow-x: auto;
    padding-bottom: 35px;
}

/* .stock-page .info-section .wrapper1 {
    position: relative;  
    width: 100%;
    height: 20px;
    display: block;
    white-space: nowrap;
    overflow-x: auto;
} */

.stock-page .info-section .table thead {
    position: relative;
    height: 40px;
    line-height: 40px;
}

.stock-page .info-section .table tbody {
    border-top: none;
}

.stock-page .info-section .table tr {
    position: relative;
    border-bottom: 1px solid #dbd0d0;
    height: 48px;
}

/* .stock-page .info-section .table tr td:nth-child(5),
.stock-page .info-section .table tr th:nth-child(5){
    display: none;
} */

.stock-page .info-section .table tbody tr td,
.stock-page .info-section .table thead tr th {
    font-size: 15px;
    text-align: center;
}

.stock-page .info-section .table tbody tr td:last-child,
.stock-page .info-section .table thead tr th:last-child {
    /* text-align: right;
    width: 100% !important;
    cursor: pointer; */
    display: none;
}

.stock-page .info-section .table tbody tr td svg {
    vertical-align: middle;
}

.stock-page .info-section .table tbody tr td span.stock-status{
    padding: 6px 15px;
    border-radius: 20px;
    color: #262626;
    cursor: pointer;
}
.stock-page .info-section .table tbody tr td span.stock-status.stock-status-reserve{
    background: #A5F1E9;
}
.stock-page .info-section .table tbody tr td span.stock-status.stock-status-en_stock{
    background: #CCD5AE;
}
.stock-page .info-section .table tbody tr td span.stock-status.stock-status-vendu{
    background: #F0997D;
}

/*************************** End info Section ***************************/

/******** Begin scrollbar css ********/
.stock-page .info-section .table::-webkit-scrollbar,
.stock-page .info-section .wrapper::-webkit-scrollbar {
    /* width: 4px; */
    height: 13px;
}

.stock-page .info-section .table::-webkit-scrollbar-track,
.stock-page .info-section .wrapper::-webkit-scrollbar-track {
    background: #e7e7e7;
    border-radius: 10px;
}

.stock-page .info-section .table::-webkit-scrollbar-thumb,
.stock-page .info-section .wrapper::-webkit-scrollbar-thumb {
    background: #67988E;
    border-radius: 20px;
}

.stock-page .info-section .table::-webkit-scrollbar-thumb:hover,
.stock-page .info-section .wrapper::-webkit-scrollbar-thumb:hover {
    background: #184438;
}

/******** End scrollbar css ********/


/*************************** Begin media ***************************/
@media screen and (max-width: 991px) {
    .stock-page .absolute-bottom-right {
        position: relative;
        transform: translateX(0);
    }

    .stock-page .form-group {
        margin: 10px 0;
    }
}



/************************************** Pagination Style **********************************************/
.stock-page .pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.stock-page .pagination .pagination-content {
    padding: 0;
    margin: 0;
    white-space: nowrap;
    border-radius: 8px;
    border: 1px solid #b8bdc7;
}

.stock-page .pagination .pagination-content span {
    list-style: none;
    background: #fff;
    padding: 10px 15px;
    display: inline-block;
    cursor: pointer;
}

@media (max-width: 556px) {
    .stock-page .pagination .pagination-content span {
        padding: 10px 7px;
    }
}

.stock-page .pagination .pagination-content span:hover {
    background: #e7e7e7;
}

.stock-page .pagination .pagination-content span:first-child {
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border-right: 1px solid #dbd4d4;
}

.stock-page .pagination .pagination-content span:last-child {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border-left: 1px solid #dbd4d4;
}

.stock-page .pagination .pagination-content span.page-number-active {
    background: #e7e7e7;
}