
.home-page .commands-section .download-table .echiancier-group{
    position: fixed;
    width: 100%;  height: 100%;
    top: 0;  left: 0;
    z-index: 100;
}
.home-page .commands-section .download-table .echiancier-group .wrapper{
    position: fixed;
    width: 100%;  height: 100%;
    top: 0;  left: 0;
    background: #67988e;
    opacity: .75;
}
.home-page .commands-section .download-table .echiancier-group .validate-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    width: 600px;
    border-radius: 30px;
}

.home-page .commands-section .download-table .echiancier-group .validate-box .validate-title{
    position: relative;
    width: 100%;
    background: #184438;
    text-align: center;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
}

.home-page .commands-section .download-table .echiancier-group .validate-box .validate-title span{
    color: #fff;
    display: inline-block;
    padding: 16px 0;
    font-size: 25px;
}

.home-page .commands-section .download-table .echiancier-group .validate-box .validate-form{
    padding: 20px 20px;
    min-height: 300px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}