

.historical-modal{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;  left: -100%;
    z-index: 101;
    padding: 30px 0;
}
.historical-modal .historical-back{
    position: absolute;
    top: 0;  left: 0;
    width: 100%;  height: 100%;
    background: #67988E;
    opacity: .81;
}
.historical-modal.display-historical-modal{
    left: 0;
}
.historical-modal .historical-line{
    position: absolute;
    top: 30px; 
    left: 50%;
    transform: translate(-50%,0);
    background: #fff;
    width: 11px;
    height: calc(100% - 60px);
    border-radius: 20px;
}
.historical-modal .no-historical{
    position: relative;
    width: 100%;
    text-align: center;
}
.historical-modal .no-historical span{
    display: inline-block;
    color: #fff;
    font-size: 75px;
    margin-top: 100px;
}
.historical-modal .box-historical{
    position: relative;
    padding: 10px 50px 10px 150px;
}
.historical-modal .row{
    margin-bottom: 0;
}
.historical-modal .row:nth-child(2n+1) .box-historical{
    padding: 10px 50px 10px 150px;
}
.historical-modal .row:nth-child(2n) .box-historical{
    padding: 10px 150px 10px 50px;
}
.historical-modal .row:nth-child(2n+1) .box-historical .title-historical{
    text-align: right;
}
.historical-modal .row:nth-child(2n) .box-historical .title-historical{
    text-align: left;
}
.historical-modal .box-historical .title-historical{
    margin-bottom: 15px;
}
.historical-modal .box-historical .title-historical span{
    position: relative;
    color: #fff;
    padding: 5px 30px;
    font-size: 19px;
    display: inline-block;
    border-radius: 25px;
}
.historical-modal .box-historical .title-historical span::before{
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.historical-modal .row:nth-child(2n+1) .box-historical .title-historical span::before{
    right: -77px;
}
.historical-modal .row:nth-child(2n) .box-historical .title-historical span::before{
    left: -77px;
}
/* Begin : Color of Differente etat  */
/* .historical-modal .box-historical .title-historical span.non-traite-color,
.historical-modal .box-historical .title-historical span.non-traite-color:before {
    background: #6FCFF5;
}

.historical-modal .box-historical .title-historical span.refuse-color,
.historical-modal .box-historical .title-historical span.refuse-color::before {
    background: #FE2400;
}

.historical-modal .box-historical .title-historical span.accepte-color,
.historical-modal .box-historical .title-historical span.accepte-color::before {
    background: #AED581;
}

.historical-modal .box-historical .title-historical span.approuve-color,
.historical-modal .box-historical .title-historical span.approuve-color::before {
    background: #107E39;
}

.historical-modal .box-historical .title-historical span.annule_par_client,
.historical-modal .box-historical .title-historical span.annule_par_client::before {
    background: #DAA801;
}

.historical-modal .box-historical .title-historical span.accuse,
.historical-modal .box-historical .title-historical span.accuse::before {
    background: #2BF5BD;
}

.historical-modal .box-historical .title-historical span.financable-color,
.historical-modal .box-historical .title-historical span.financable-color::before {
    background: #d581cb;
}

.historical-modal .box-historical .title-historical span.non-financable-color,
.historical-modal .box-historical .title-historical span.non-financable-color::before {
    background: #7d828d;
}

.historical-modal .box-historical .title-historical span.controler-color,
.historical-modal .box-historical .title-historical span.controler-color::before {
    background: #262626;
} */
/* End : Color of Differente etat  */


.historical-modal .box-historical .content-historical{
    background: #fff;
    border-radius: 20px;
    padding: 17px 30px;
    font-size: 18px;
}
.historical-modal .box-historical .content-historical .line-historical{
    margin: 10px 0;
}