
/* Begin of Modal CSS */
.validate-modal{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;  left: -100%;
    z-index: 101;
}
.validate-modal .validate-back{
    position: absolute;
    top: 0;  left: 0;
    width: 100%;  height: 100%;
    background: #67988E;
    opacity: .81;
}
.validate-modal.display-modal{
    left: 0;
}
.validate-modal .validate-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    width: 600px;
    min-height: 400px;
    border-radius: 30px;
    overflow: hidden;
}

.validate-modal .validate-box .validate-title{
    position: relative;
    width: 100%;
    background: #184438;
    text-align: center;
}

.validate-modal .validate-box .validate-title span{
    color: #fff;
    display: inline-block;
    padding: 16px 0;
    font-size: 25px;
}

.validate-modal .validate-box .validate-form{
    padding: 33px 20px;
}

.validate-modal .validate-box .validate-form label {
    font-size: 19px;
    padding: 8px 0;
}

.validate-modal .validate-box .validate-form input,
.validate-modal .validate-box .validate-form textarea,
.validate-modal .validate-box .validate-form select {
    border-radius: 20px;
    border: 1px solid #a7a7a7;
    padding: 8px 15px;
}

.validate-modal .validate-box .validate-form input:focus,
.validate-modal .validate-box .validate-form textarea:focus,
.validate-modal .validate-box .validate-form select:focus {
    box-shadow: none;
    border: 1px solid #6FCFF5;
}

.validate-modal .validate-box .validate-form input::placeholder {
    color: #A2A8B4;
}

.validate-modal .validate-box .validate-submit{
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.validate-modal .validate-box .validate-submit span{
    background: #184438;
    color: #fff;
    font-size: 20px;
    padding: 8px 25px;
    border-radius: 30px;
    cursor: pointer;
}
.validate-modal .validate-box .validate-submit span svg{
    vertical-align: middle;
    margin-left: 10px;
}