
.statistiques-aujourdhui{
    position: relative;
    width: 100%;
    background: #fff;
    border-radius: 20px;
    padding: 20px 30px;
}
.statistiques-aujourdhui h1,.statistiques-aujourdhui h2,.statistiques-aujourdhui h3{
    margin-bottom: 0;
}
.justify-center{
    justify-content: center;
}
.margin-top{
    margin-top: 25px;
}
.statistiques-aujourdhui h1{
    font-size: 23px;
}
.statistiques-aujourdhui h1 svg{
    vertical-align: text-top;
}
.statistiques-aujourdhui .box{
    background: linear-gradient(90deg,#FF7A49,#F89C4E);
    border-radius: 27px;
    padding: 35px 15px;
    display: flex;
    justify-content: center;
    color: #fff;
}
.statistiques-aujourdhui .box .info{
    margin-left: 10px;
}
.statistiques-aujourdhui .box h3{
    font-size: 18px;
}
.statistiques-aujourdhui .box h2{
    font-size: 35px;
}
.statistiques-aujourdhui .box h2 span{
    font-size: 20px;
}