

.detail-page {
    padding: 165px 85px;
}

.detail-page .no-data-wrapper{
    background: #fff;
    font-size: 30px;
    padding: 20px 30px;
    text-align: center;
}

.detail-page .back{
    text-decoration: none;
    color: #67988e;
    border: 1px solid #67988e;
    border-radius: 50px;
    padding: 3px 25px;
    margin-bottom: 20px;
    display: inline-block;
    font-size: 20px;
}

.detail-page .back svg{
    vertical-align: text-bottom;
    margin-right: 5px;
}

.detail-page .box{
    position: relative;
    margin-bottom: 35px;
}

.detail-page .box .title, .detail-page .detail-download{
    color: #fff;
    background: linear-gradient(90deg, #67988E, #184438);
    padding: 7px 50px 0px 25px;
    font-size: 20px;
    display: inline-block;
    border-radius: 25px;
}
.detail-page .detail-download{
    margin-bottom: 18px;
    padding: 8px 28px 8px 28px;
    text-decoration: none;
    font-size: 18px;
    border: none;
}
.detail-page .box .title span{
    vertical-align: text-bottom;
    margin-left: 12px;
}

.detail-page .box ul{
    background: #fff;
    margin-top: 15px;
    border-radius: 50px;
    padding: 12px 50px !important;
}

.detail-page .box ul li{
    list-style: none;
    margin: 12px 0;
    font-size: 19px;
}
.detail-page .box ul.cart li{
    border-bottom: 1px solid #d1d1d1;
}
.detail-page .box ul.cart li:last-child{
    border: none;
}

.detail-page .box ul li span:nth-child(1){
    color: #262626;
}
.detail-page .box ul li > span:nth-child(2){
    margin-left: 10px;
}
.detail-page .box ul li span .fa{
    font-size: 27px;
    color: #1f4c40;
    margin-right: 15px;
}
.detail-page .box ul li span a{
    text-decoration: none;
}
.detail-page .alsalam-big-logo{
    position: fixed;
    top: 140px;
    right: 40px;
    opacity: 0.4;
}
.detail-page .box ul li img{
    width: 100px;
    height: 100px;
}
.detail-page .box ul li .ligne span{
    font-size: 16px;
}
.detail-page .box ul li .ligne.font-size-19 span{
    font-size: 19px;
}
.detail-page .box ul li .ligne span:nth-child(1){
    color: #262626;
}
.detail-page .box ul li .ligne span:nth-child(2){
    color: #ACACAC;
}
.detail-page .modal-buttons{
    position: relative;
    text-align: center;
}
.detail-page .modal-buttons span{
    background: #184438;
    color: #fff;
    display: inline-block;
    padding: 7px 35px;
    font-size: 20px;
    letter-spacing: 1px;
    border-radius: 30px;
    margin: 10px 20px;
    cursor: pointer;
}

